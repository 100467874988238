import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import Link from '../../components/Common/Link'
import List from '../../components/Common/List'
import { Button } from '../../components/Common/Button'
import { toLink } from '../../utils'

import { useTranslations } from '../../hooks/use-translations'
import { PathEnum } from '../../types/pages'

import * as s from '../../pages/news.module.scss'

const UniformatRusForBimModeling: React.FC = () => {
  const { getPath } = useTranslations()
  return (
    <>
      <SEO
        title="Российская адаптация международного классификатора Uniformat"
        description="Эта статья про применение русифицированного классификатора uniformat в России. Этот классификатор поможет компаниям, у которых нет своего классификатора или заказчик не прописал в требованиях свой, заполнять параметры Код по классификатору."
      />
      <NewsSection
        title="Российские разработчики ПО SIGNAL, Tangl и IYNO перевели Uniformat II 2015."
        dateData={{ date: '24.01.2024' }}
        rayContent
      >
        <h2 className={s.textTitle}>Введение</h2>
        <p className={s.text}>
          Российские разработчики: SIGNAL (
          <GatsbyLink to={getPath(PathEnum.Home)} className="has-text-primary">
            sgnl.pro
          </GatsbyLink>
          ), Tangl (
          <Link
            href="https://tangl.cloud"
            rel="nofollow noreferrer"
            target="_blank"
          >
            tangl.cloud
          </Link>
          ) и IYNO (
          <Link
            href="https://iyno.ru"
            rel="nofollow noreferrer"
            target="_blank"
          >
            iyno.ru
          </Link>
          ) перевели классификатор Uniformat II 2015 года (ASTM E1557) и
          выложили его в открытый доступ.
        </p>
        <p className={s.text}>
          А также написали статью о способах его применения и провели вебинар с
          примерами его использования.
        </p>
        <div className="w-100 flex justify-center">
          <Button
            size="large"
            className="my-4"
            onClick={() =>
              toLink('https://www.youtube.com/watch?v=ISFUqe7XtWE')
            }
          >
            Смотреть запись
          </Button>
        </div>
        <p className={s.text}>
          Скачать можно здесь:{' '}
          <GatsbyLink
            to={getPath(PathEnum.Uniformat)}
            className="has-text-primary"
          >
            https://sgnl.pro/uniformat
          </GatsbyLink>
          ,{' '}
          <Link
            href="https://help.tangl.cloud/uniformat"
            rel="nofollow noreferrer"
            target="_blank"
          >
            https://help.tangl.cloud/uniformat
          </Link>{' '}
          или{' '}
          <Link
            href="https://iyno.ru/uniformat"
            rel="nofollow noreferrer"
            target="_blank"
          >
            https://iyno.ru/uniformat
          </Link>
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-1.png"
            alt="Классификатор Uniformat в Revit"
            title="Классификатор Uniformat в Revit"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Не секрет, что на сегодняшний день на рынке нет практики использования
          единообразных коммерческих классификаторов. Каждый застройщик
          разрабатывает что-то своё.
        </p>
        <p className={s.text}>
          Однако наша практика и запросы клиентов говорят о том, что базовые
          потребности и цели для систематизации элементов в проектах, подсчета
          объемов, учета строительных работ, расчета финансовых моделей и т.д.
          по своей сути одинаковы.
        </p>
        <p className={s.text}>
          Как следствие, многие застройщики осознанно или неосознанно используют
          очень похожие или вовсе одинаковые в своей основе классификаторы,
          которые могут отличаться незначительно.
        </p>
        <p className={s.text}>
          Отсюда у нас появилась инициатива взять за основу понятный, известный,
          простой и популярный во всем мире классификатор и перевести его для
          использования в наших реалиях. Что мы и сделали в качестве первого
          шага.
        </p>
        <p className={s.text}>
          Мы предлагаем использовать данный классификатор для заполнения в
          параметре типоразмера Код по классификатору, в случае если у компании
          нет своего классификатора или Заказчик не прописал в требованиях свой.
        </p>
        <p className={s.text}>
          Также, мы рекомендуем компаниям Заказчикам, которые заказывают BIM
          своим проектировщикам, но не имеют своего внутреннего классификатора
          элементов, включить следующий пункт в Информационные требования
          заказчика или техническое задание на проектирование:
        </p>
        <p className={s.text}>
          “Все элементы BIM-модели, отображаемые на 3D виде или учитываемые при
          составлении ведомостей, спецификаций, смет, должны быть
          классифицированы по классификатору UNIFORMAT 2015 RU, скачать который
          можно по ссылке{' '}
          <GatsbyLink
            to={getPath(PathEnum.Uniformat)}
            className="has-text-primary"
          >
            https://sgnl.pro/uniformat
          </GatsbyLink>
          ”
        </p>
        <h2 className={s.textTitle}>Что такое Uniformat?</h2>
        <p className={s.text}>
          Uniformat - это система классификации строительных элементов, систем и
          сборок для зданий и сооружений. Иерархическая структура элементов,
          структурированная по функциональному признаку.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-2.png"
            alt="Уровни классификации элементов по UNIFORMAT II"
            title="Уровни классификации элементов по UNIFORMAT II"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Сущностями классификатора являются сборки элементов здания, например:
          Фундаментная плита, Несущие конструкции этажа, Сети вентиляции,
          Оборудование отопления.
        </p>
        <p className={s.text}>
          Предполагается, что такое логическое структурирование информации об
          объекте, может позволить связать разрозненные данные, описать их
          последовательно, позволить быстрее найти нужную информацию из смежных
          отделов.
        </p>
        <p className={s.text}>
          Например позволяет поиском найти все акты, подрядчиков и выданные
          замечания по интересующему вас коду сборки или посмотреть элементы
          BIM-модели и их объем.
        </p>
        <p className={s.text}>
          Классификатор Uniformat является верхнеуровневым, который задает общую
          структуру данных на проекте и предполагает, что каждая компания может
          его расширять под свои нужды, например, если не хватает группы, то
          добавить свою. Это достигается оставленными разрядами между позициями
          10 и 20 например.
        </p>
        <h2 className={s.textTitle}>История развития</h2>
        <p className={s.text}>
          В 1973 году компания Hanscomb Associates (консультант по расчету и
          ведению бюджета) разработала систему под названием MASTERCOST для
          Американского института архитекторов (AIA). В это же время управление
          США General Services Administration (GSA), отвечающее за
          правительственные здания, также разрабатывало свою систему. Эти
          организации решили объединить усилия и разработали единую структуру,
          AIA и GSA согласовали систему и назвали ее UNIFORMAT. AIA включило его
          в свою практику управления строительством, а GSA включило его в свои
          требования к оценке проекта.
        </p>
        <p className={s.text}>
          В 1989 году ASTM International приступила к разработке стандарта
          классификации строительных элементов на основе UNIFORMAT. Он был
          переименован в UNIFORMAT II. В 1995 году Институт строительных
          спецификаций (CSI) и Строительные спецификации Канады (CSC) начали
          пересмотр Uniformat. UniFormat теперь является товарным знаком CSI и
          CSC и последний раз был опубликован в 2010 году.
        </p>
        <p className={s.text}>
          Изначально классификатор разрабатывался для сметчиков, чтобы в
          определенной структуре планировать затраты на проекте и затем их
          закрывать.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-3.png"
            alt="Эволюция развития и применения классификатора UNIFORMAT"
            title="Эволюция развития и применения классификатора UNIFORMAT"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Затем классификационная система Uniformat была включена в общую
          систему таблиц классификации - OmniClass. На его основе была сделана
          таблица 21 OmniClass - отраслевого строительного классификатора в США.
          Эта таблица называется Elements.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-4.png"
            alt="Эволюция развития и применения классификатора UNIFORMAT"
            title="Эволюция развития и применения классификатора UNIFORMAT"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          В настоящее время имеются еще 2 таблицы: Masterformat (23 таблица
          Omniclass - Products) и 22 таблица OmniClass - Work Results.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-5.png"
            alt="Структурирование бюджета по OmniClass"
            title="Структурирование бюджета по OmniClass"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Masterformat применяется для структурирования бюджета на стадии
          строительства и детального календарного графика.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-6.jpeg"
            alt="Группы требований к закупкам и заключению контрактов"
            title="Группы требований к закупкам и заключению контрактов"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Work Results - применяется для структурирования видов работ по
          группам, например для кодирования нормативных документов по выполнению
          работ и контролю качества (в США называются specifications). Полезно
          для быстрого поиска и организации каталога документов типа наших
          ГОСТов для строительных процессов.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-7.png"
            alt="Структурирование работ по группам"
            title="Структурирование работ по группам"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Есть схожие таблицы и в других классификаторах, отвечающие за
          определенные разрезы данных.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-8.png"
            alt="Сравнение таблиц в других классификаторах"
            title="Сравнение таблиц в других классификаторах"
            placeholder="blurred"
          />
        </div>
        <h2 className={s.textTitle}>
          Для чего используется классификатор Uniformat в мире?
        </h2>
        <p className={s.text}>
          Uniformat применяется для классификации элементов BIM-модели. Его код
          по умолчанию предлагают выбрать в параметре Код по классификатору
          (Assembly code) в Revit. В дальнейших задачах по сбору объемов или
          маппингом с календарно-сетевым графиком, если даже и требуется более
          детальная разбивка, то единая структура на верхнем уровне существенно
          помогает. Порядок в любом случае лучше, чем его отсутствие и при
          наличии любого классификатора, зачастую его можно автоматизированно
          привести к другому.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-9.png"
            alt="Назаначение Кода классификатора в Revit"
            title="Назаначение Кода классификатора в Revit"
            placeholder="blurred"
          />
        </div>
        <div className="flex justify-center">
          <StaticImage
            className={cn(s.boxShadow, 'mr-4 my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-10.png"
            alt="Назаначение Кода классификатора в Revit"
            title="Назаначение Кода классификатора в Revit"
            placeholder="blurred"
          />
          <StaticImage
            className={cn(s.boxShadow, 'my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-11.png"
            alt="Назаначение Кода классификатора в Revit"
            title="Назаначение Кода классификатора в Revit"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Файл “UNIFORMAT 2015 RU.txt” для подгрузки в Revit находится по ссылке{' '}
          <GatsbyLink
            to={getPath(PathEnum.Uniformat)}
            className="has-text-primary"
          >
            https://sgnl.pro/uniformat
          </GatsbyLink>
        </p>
        <p className={s.text}>
          Также Uniformat применяется в различных международных компаниях для
          структурирования видов работ в бюджете и календарном графике, создания
          верхнего уровня (до 3-4го) WBS (work breakdown structure - структуры
          разбивки работ) для проектов строительства зданий. При этом позиции на
          более низких уровнях предполагается добавлять из других
          классификаторов, как бы вкладывая в папку позиции Uniformat’а.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-12.png"
            alt="Выгрузка объемов работ по классификатору"
            title="Выгрузка объемов работ по классификатору"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Например, имеются примеры ведения на ранних стадиях бюджета в
          структуре Uniformat, а на более поздних стадиях, на этапе
          строительства, расшивки классификатора Uniformat, вкладывания внутрь
          каждой позиции из Masterformat.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-13.png"
            alt="Мапинг Uniformat и Masterformat на этапе строительства"
            title="Мапинг Uniformat и Masterformat на этапе строительства"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          В других же случаях происходит маппинг (сопоставление) позиций для
          связи бюджетов составленных с использованием структуры Uniformat и
          Masterformat.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-14.png"
            alt="Связи бюджетов составленных с использованием  Uniformat и Masterformat"
            title="Связи бюджетов составленных с использованием  Uniformat и Masterformat"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          В России аналог - это перечень локальных сметных расчетов для сводного
          сметного расчета или Структуры бюджета и календарно-сетевого графика
          принятые внутри компаний.
        </p>
        <p className={s.text}>
          В любом случае применение единого классификатора для разбивки всех
          бюджетов компании (или даже в отрасли) удобно тем, что можно легко
          сопоставлять затраты между проектами по идентичным статьям затрат. Это
          помогает отслеживать реальные изменения цен и актуализировать расценки
          для дальнейших расчетов.
        </p>
        <p className={s.text}>
          Uniformat применяется для оценки стоимости объекта на ранних стадиях
          проектирования, когда у каждой сборки есть свой ключевой параметр
          объема (например от площади помещений, объема бетона, площади
          фасадов), на который рассчитывается вся стоимость сборки, для
          предварительного расчета бюджета.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-15.png"
            alt="Сопоставление предварительного описания проекта со сводной сметой расходов"
            title="Сопоставление предварительного описания проекта со сводной сметой расходов"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Данная методика расчета точнее чем расчет от общей площади или
          производительности объекта, как по советским методикам, применяющимся
          сейчас в РФ. Но, конечно, точность ниже чем сметный расчет после
          стадии П.
        </p>
        <p className={s.text}>
          Таким образом данный расчет не позволяет заменить собой первичный (от
          плановой площади или производительности) и сметный, но полезен для
          более точной оценки после предпроекта (стадии концепции), потому что
          может подсветить специфику проекта, которая может привести к
          удорожанию относительно первичного расчета. Такие вещи лучше выявлять
          на ранних стадиях, чтобы не узнать, что не вписываетесь в бюджет, уже
          после разработки и обсчета стадии П, на пол года позже, что приводит
          просто к заморозке проекта.
        </p>
        <p className={s.text}>
          Также классификатор предлагается использовать в некоторых методических
          рекомендациях для описания проекта, формирования структуры
          технического задания на проектирование и организации структуры
          хранения технической документации на проекте.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'mx-auto my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-16.png"
            alt="Срезы таблиц Uniformat"
            title="Срезы таблиц Uniformat"
            placeholder="blurred"
          />
        </div>
        <div className="flex justify-center">
          <StaticImage
            className={cn(s.boxShadow, 'mr-4 my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-17.png"
            alt="Срезы таблиц Uniformat"
            title="Срезы таблиц Uniformat"
            placeholder="blurred"
          />
          <StaticImage
            className={cn(s.boxShadow, 'my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-18.png"
            alt="Срезы таблиц Uniformat"
            title="Срезы таблиц Uniformat"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          В мире, США и Европе распространена практика, что независимые
          коммерческие компании ведут свои базы справочников расценок для разных
          регионов и продают подписку на них компаниям (в том числе
          государственным органам и госкомпаниям), кому требуется считать сметы.
          Иногда эти справочники также структурированы в кодировке Uniformat или
          имеют дополнительные параметры для сопоставления с ним. Также могут
          вестись в данной структуре стандарты на выполнение работ
          (specifications). Это позволяет быстро найти требуемые расценки и
          документы для заинтересованных.
        </p>
        <div className="flex justify-center items-end">
          <StaticImage
            className={cn(s.boxShadow, 'mr-4 my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-19.png"
            alt="Использование uniformat в справочниках расценок"
            title="Использование uniformat в справочниках расценок"
            placeholder="blurred"
          />
          <StaticImage
            className={cn(s.boxShadow, 'my-4')}
            src="../../assets/images/news/uniformat-rus-for-bim-modeling/image-20.png"
            alt="Использование uniformat в справочниках расценок"
            title="Использование uniformat в справочниках расценок"
            placeholder="blurred"
          />
        </div>
        <h2 className={s.textTitle}>Выводы.</h2>
        <h3 className={s.textTitle} style={{ color: '#8F98A1' }}>
          Почему UNIFORMAT?
        </h3>
        <p className={s.text}>
          Uniformat является хорошим примером как можно сделать классификатор
          строительной отрасли. Он является универсальным, понятным, позволяет
          разложить данные согласно иерархии по функциональному признаку, по
          сборкам строительных конструкций и инженерных систем, элементам. На
          его основе коммерческие департаменты и департаменты планирования
          создают структуры разбивки работ (WBS) внутри своих компаний по всему
          миру.
        </p>
        <p className={s.text}>
          Перевод данного формата позволит стандартизировать работу девелоперов
          в BIM, позволит нам всем двигаться дальше в части автоматизированного
          получения объемов из BIM модели и связке ее с бюджетом проекта и
          календарным графиком. Даже, если и не в лоб применять, то использовать
          для доработки, расширения своих, а также организации маппинга своих
          классификаторов с иностранными.
        </p>
        <h3 className={s.textTitle} style={{ color: '#8F98A1' }}>
          Почему не КСИ?
        </h3>
        <p className={s.text}>
          На данный момент государство предлагает участникам рынка использовать
          КСИ. Однако у данного классификатора есть ряд незакрытых моментов
          (далее речь пойдет о 4,5 и 6 таблицах, которые предлагается
          использовать для BIM).
        </p>
        <p className={s.text}>
          Фасетная классификация призвана прежде всего автоматизировать
          генерацию полного кода любого элемента, который бы описывал его
          параметры, поведение и назначение.
        </p>
        <p className={s.text}>
          Взамен она требует строгого соблюдения ряда принципов, без которых её
          использование станет невозможным или крайне затрудненным. А именно:
        </p>
        <List className={s.textLists}>
          <li>
            глубокий анализ и исключение конфликтов между таблицами фасетов;
          </li>
          <li>значительное упрощение самих отдельных таблиц;</li>
          <li>
            а самое главное – детально прописанную методологию и алгоритмы,
            инструменты автоматизированного анализа и генерации кодов. Ручная
            кодификация тут уже почти невозможна или нецелесообразна.
          </li>
        </List>
        <p className={s.text}>
          В КСИ все эти проблемы не решены. Мы имеем сложную систему
          соподчинения таблиц и кодов, наличие конфликтов в них, отсутствие
          пошаговой методологии кодификации в модели с примерами, разбором
          частных случаев, отсутствие понимания, как его автоматизировано
          назначать в моделях (а в ручную это делать запредельно сложно) и массу
          других проблем.
        </p>
        <p className={s.text}>
          Как результат – настолько высокий порог входа в процесс использования
          КСИ, что этот процесс становится мучительным, нерентабельным и
          затратным как в плане сил, времени, так и экономической выгоды и
          целесообразности.
        </p>
        <p className={s.text}>
          На это накладывается непонятное наименование групп классов и странный
          способ формирования сводного кода со спецсимволами (++,-,=),
          затрудняющими работу в Excel или иных табличных редакторах, в которых
          по идее должны вестись маппинги и доработка структуры.
        </p>
        <p className={s.text}>
          Безусловно, система классификации КСИ будет развиваться, но на данный
          момент нет эффективного диалога между участниками строительного рынка
          и государством. Мы предлагаем не изобретать велосипед, а взять за
          основу проверенный временем базовый международный классификатор и
          дорабатывать его (или на его основе делать новый) для эффективного
          развития цифрового строительства в России.
        </p>
        <p className={s.text}>
          Авторы данной статьи планируют провести вебинар с примерами применения
          данного классификатора в BIM с использованием их ПО, для получения
          объемов работ и материалов.
        </p>
        <p className={s.text}>
          В случае возникновения замечаний или предложений, уточнений по данной
          статье или переводу классификатора, пишите в телеграм{' '}
          <Link
            href="https://t.me/popov_bim"
            rel="nofollow noreferrer"
            target="_blank"
          >
            https://t.me/popov_bim
          </Link>
        </p>
      </NewsSection>
    </>
  )
}

export default UniformatRusForBimModeling
